<template>
  <div class="container mt-5 with-footer">
    <b-card class="card" header="Cenová politika">

      <b-input-group class="policy-search">
        <b-form-input @update="searchProduct" debounce="500" type="search" v-model="searchInput"
                      placeholder="Vyhľadajte tovar podľa názvu alebo produktového čísla" />
          <svg class="search-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6164 10.9301L8.76034 7.95969C9.49467 7.08675 9.89702 5.98841 9.89702 4.845C9.89702 2.17351 7.72351 0 5.05203 0C2.38054 0 0.207031 2.17351 0.207031 4.845C0.207031 7.51648 2.38054 9.68999 5.05203 9.68999C6.05494 9.68999 7.01067 9.38749 7.82779 8.81326L10.7055 11.8062C10.8258 11.9311 10.9876 12 11.1609 12C11.325 12 11.4807 11.9374 11.5989 11.8237C11.85 11.5821 11.858 11.1814 11.6164 10.9301ZM5.05203 1.26391C7.02668 1.26391 8.63311 2.87034 8.63311 4.845C8.63311 6.81965 7.02668 8.42608 5.05203 8.42608C3.07737 8.42608 1.47094 6.81965 1.47094 4.845C1.47094 2.87034 3.07737 1.26391 5.05203 1.26391Z" fill="#C4C4C4"/>
          </svg>
      </b-input-group>


      <b-table striped hover :items="tableItems" :fields="fields" class="search-table policy-table" :tbody-tr-class="rowClass">
        <template #cell(badge)="value">
          <VendorBadge :vendor-id="value.item.vendor_id"/>
        </template>
        <template #cell(name)="value">
        <div class="policy-title">
            <div class="policy-code">
              #{{ value.item.oid }}
            </div>
          <span class="font-weight-bolder">{{ value.item.name }}</span>
        </div>
        </template>
        <template #cell(price_custom)="value" class="testing">
          <div>
            <span class="old-price" v-if="showOldPrice(value.item) ">{{ formatPrice(value.item.price_original) }} &euro; </span>

            <b-input-group size="sm" append="€">
              <b-form-input
                  class="text-right"
                  type="number"
                  step="0.01"
                  min="0"
                  @update="changePricing($event, value.item)"
                  :value="value.item.price"
                  :placeholder="formatPrice(value.item.price_original)"/>
            </b-input-group>
          </div>
        </template>
        <template #cell(updated_at)="value">
         <span v-if="value.item.updated_at">{{ formatNow(value.item.updated_at) }}</span>
        </template>
        <template #cell(actions)="value">
          <template>
            <b-dropdown right class="policy-drop">
              <template #button-content v-if="value.item.updated_at">
               <span>...</span>
              </template>
              <b-dropdown-item @click.prevent="removePolicy(value, $event)">Vymazať</b-dropdown-item>
            </b-dropdown>
          </template>
        </template>
      </b-table>
    </b-card>
    <SubmitFooter :on-submit="onSubmit" v-if="policiesUpdateQueue.length" />
  </div>
</template>

<script>
  import Vue from 'vue'
  import {mapActions, mapGetters, mapMutations} from "vuex"
  import VendorBadge from "@/plugins/app@enums/vendor-badge"
  import SubmitFooter from "@/plugins/app/_layouts/components/submit-footer"
  import format from "@/plugins/app/formatters"

  export default Vue.extend({
    components: {SubmitFooter, VendorBadge},
    props: ['vendorId'],
    data: function () {
      return {
        searchInput: '',
        fields: [
          { key: 'badge', label:'', class:''},
          { key: 'name', label: 'Tovary', headerTitle: 'Tovary'},
          { key: 'updated_at', label: 'Upravené', class: 'updated-at'},
          { key: 'price_custom', label: '', class: 'custom-price'},
          { key: 'actions', label: ''},
        ]
      }
    },
    computed: {
      ...mapGetters('policies', ['policies', 'policiesSearch', 'policiesUpdateQueue']),
      vendor: function () {
        return this.vendorsMap[this.vendorId]
      },
      tableItems: function () {
        return this.policiesSearch.length ? this.policiesSearch : this.policies
      }
    },
    mounted() {
      this.loadUserPolicies()
    },
    beforeDestroy() {
      this.clearSearchPolicies()
    },
    methods: {
      ...mapActions('policies', ['searchPolicy', 'submitPolicyUpdate', 'loadUserPolicies']),
      ...mapMutations('policies', ['updatePolicyPricing', 'clearSearchPolicies']),
      searchProduct: function (s) {
        if(s.length < 3) {
          this.clearSearchPolicies()
          return
        }

        this.searchPolicy(s)
      },

      onSubmit: async function () {
        await this.submitPolicyUpdate().then(_ => {
          this.searchInput = ''
          this.$wToast.success('Zmeny boli uložené!')
        }).catch(e => {
          this.$wToast.error(e)
        })
      },

      showOldPrice: function (value) {
        const res = this.policiesUpdateQueue.filter(v => v.oid === value.oid && !v.price_custom).length
        return !res && value.price_custom
      },

      removePolicy(v, $event) {
        // Fucking dirty hack
        $event.target.closest('tr').querySelector('.custom-price input').value = ''
        this.updatePolicyPricing({oid: v.item.oid, newPrice: null})
      },

      rowClass(value) {
        const res = !!this.policiesUpdateQueue.filter(v => v.oid === value.oid).length
        return res ? "edited" : ""
      },

      changePricing: function (v, item) {
        const newPrice = parseFloat(v)
        const oid = item.oid

        if(newPrice == item.price_custom)
          return

        if(!newPrice || newPrice == item.price_original)
          this.updatePolicyPricing({oid, newPrice: null})
        else
          this.updatePolicyPricing({oid, newPrice})

        this.$forceUpdate()
      },

      formatNow: format.now,
      formatPrice: format.price,
    }
  })
</script>
<style lang="scss">
table.policy-table {
  tbody {
    tr {
      &.edited td{
        background: #fff7de !important;
      }

      border-radius: 4px;
      .tr-indent{
        &:after{
          content:'';
          height: 5px;
          display: block;
        }
      }
      &:nth-of-type(odd){
        background-color: #ffffff;
      }
      td{
        &:nth-child(1){
          width: 2.25%;
          padding-right: 0;
        }
        &:nth-child(2){
          width: 55%;
          .policy-title{
            display: flex;
            flex-direction: column;
          }
        }
        &:nth-child(3){
          width: 20%;
        }
        &:nth-child(4){
          width: 18%;
          .old-price{
            font-size: 10px;
            display: flex;
            align-items: center;
            color: #A6A6A6;
            margin-right: 8px;
          }
          .input-group{
            border: 1px solid #E7E7E7;
            border-radius: 4px;
            input{
              color: #252525;
              border: none;
              &::placeholder{
                color: #A6A6A6;
              }
            }
            .input-group-append{
              border: none;
              .input-group-text{
                font-size: 12px;
                background-color: #F7F7F7;
                border: none;
              }
            }
          }
        }
        &:nth-child(5){
          padding-left: 0;
          width: 2%;
          button{
            background-color: transparent!important;
            border: none;
            &:after{
              display: none;
            }
            &:focus{
              background-color: transparent;
              box-shadow: none;
              &:active{
                box-shadow: none;
              }
            }
            &:active{
              background-color: transparent;
              box-shadow: none;
            }
            span{
              display: block;
              margin-top: -8px;
              color: #A6A6A6;
            }
          }
          .dropdown-menu {
            padding: 9px 5px;

            .dropdown-item {
              padding: 8px 5px;
            }
          }
        }
      }
    }
  }
}
  .badge{
    display: table-cell;
  }
  .custom-price {
    max-width: 130px;
    text-align: right;

    > div {
      display: flex;
      float: right;
    }
    .input-group{
      max-width: 100px;
    }
  }
  .old-price {
    white-space: nowrap;
    display: inline-block;
    text-decoration: line-through;
    font-size: 80%;
    margin-right: 0.5em;
  }
  .price-input {
    background: black;
  }
</style>
